
import HomePage from '../pages/home.vue';

var routes = [
  {
    path: '/',
    component: HomePage,
  },
];

export default routes;
